import $ from 'jquery';

$(() => {
    // Navigation Touch Geräte
    $('ul.navbar-nav > li.nav-menu > a').on('touchend', function (e) {
        if (!$(this).parent().hasClass('hovered')) {
            e.preventDefault();
            $(this).parent().toggleClass('hovered');
            $(this).parent().siblings().removeClass('hovered');
            $(this).closest('.navbar-nav').parent().siblings().find('ul.navbar-nav > li.nav-menu').removeClass('hovered');
        }
    });


    {
        let scroll = $(window).scrollTop();
        const header = $('header');

        $(window).on('scroll', () => {
            scroll = $(window).scrollTop();
            if (scroll >= 180) {
                header.addClass("fixed--header");
                $('.global-news-stopper').addClass('deactivated');
            } else {
                header.removeClass("fixed--header");
                $('.global-news-stopper').removeClass('deactivated');
            }
        });

        //
        $('.global-news-stopper__badge').on('click', function () {
            $(this).closest('.global-news-stopper').toggleClass('deactivated');
        });
    }
});
